






























































import { defineComponent } from '~/utils/nuxt3-migration'
import CDragScroll from '~/components/shared/configurable/CDragScroll.vue'
import Searchbar from '~/components/shared/classified/search/searchbar/Searchbar.vue'
import IndexCategoryIcon from '~/components/car/classifieds/index/jumbotron/IndexCategoryIcon.vue'
import { CIcon } from '~/icons/types'
import { CategoryId } from '~/models/category/types'
import { ciXymaInactive } from '~/icons/source/regular/xyma-inactive'
import { ciXyma } from '~/icons/source/duotone/xyma'
import { ciJobsInactive } from '~/icons/source/regular/jobs-inactive'
import { ciJobs } from '~/icons/source/duotone/jobs'
import { ciRealEstateInactive } from '~/icons/source/regular/real-estate-inactive'
import { ciRealEstate } from '~/icons/source/duotone/real-estate'
import { ciVehiclesInactive } from '~/icons/source/regular/vehicles-inactive'
import { ciVehicles } from '~/icons/source/duotone/vehicles'
import { ciPartsAccessoriesInactive } from '~/icons/source/regular/parts-accessories-inactive'
import { ciPartsAccessories } from '~/icons/source/duotone/parts-accessories'
import { formatNumber } from '~/utils/number'
import { mapGetters, mapState } from 'vuex'
import { INDEX_NS } from '~/store/modules/shared/index/state'
import Facets from '~/components/shared/quick-search/facets/Facets.vue'
import { Config } from '~/models/quick-search/types'
import { QUICK_SEARCH_NS } from '~/store/modules/shared/quickSearch/state'
import {
  RESET_PARAMS,
  SET_CONFIG
} from '~/store/modules/shared/quickSearch/mutation-types'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'

interface IndexSearchableCategory {
  icon: CIcon
  iconActive: CIcon
  title: string
  catId: number
}
export default defineComponent({
  components: { Facets, IndexCategoryIcon, Searchbar, CDragScroll },
  data() {
    return {
      selectedCategory: CategoryId.VEHICLES
    }
  },
  computed: {
    ...mapState(INDEX_NS, {
      classifiedsCount: state => state.count
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    isTouchDevice() {
      return process.client && 'ontouchstart' in window
    },
    vehiclesCatId() {
      return CategoryId.VEHICLES
    },
    partsCatId() {
      return CategoryId.PARTS
    },
    xymaCatId() {
      return CategoryId.XYMA
    },
    plotCatId() {
      return CategoryId.PLOT
    },
    jobsCatId() {
      return CategoryId.JOBS
    },
    placeholder() {
      let pl
      switch (this.selectedCategory) {
        case this.vehiclesCatId:
          pl = this.$t('what vehicle are you looking for?')
          break
        case this.partsCatId:
          pl = this.$t('what part are you looking for?')
          break
        default:
          pl = this.$t('what are you looking for?')
      }
      return pl
    },
    indexSearchableCategories(): IndexSearchableCategory[] {
      return [
        {
          icon: ciVehiclesInactive,
          iconActive: ciVehicles,
          title: this.$t('vehicles') as string,
          catId: this.vehiclesCatId
        },
        {
          icon: ciPartsAccessoriesInactive,
          iconActive: ciPartsAccessories,
          title: this.$t('parts') as string,
          catId: this.partsCatId
        },
        {
          icon: ciXymaInactive,
          iconActive: ciXyma,
          title: this.$t('classifieds::xyma') as string,
          catId: this.xymaCatId
        },
        {
          icon: ciRealEstateInactive,
          iconActive: ciRealEstate,
          title: this.$t('real estate') as string,
          catId: this.plotCatId
        },
        {
          icon: ciJobsInactive,
          iconActive: ciJobs,
          title: this.$t('jobs') as string,
          catId: this.jobsCatId
        }
      ]
    },
    formattedClassifiedCount() {
      return formatNumber(this.classifiedsCount)
    }
  },
  mounted() {
    if (!this.isTouchDevice) {
      this.$refs.searchbar.silentFocus()
    }
  },
  methods: {
    async selectCategory(catId: CategoryId) {
      if (catId === CategoryId.PLOT) {
        this.fetchPlotFacets()
      }
      this.selectedCategory = catId
      this.$emit('select-category', this.selectedCategory)
      const elem = this.$refs['cat' + catId][0]

      if (elem.$el) {
        this.$refs.scroller.scrollToElementIfOverflown(elem.$el)
      }

      await this.$nextTick()

      if (this.$refs.searchbar) {
        this.$refs.searchbar.onRootCategoryChange()
      }
    },
    async fetchPlotFacets() {
      this.$store.commit(`${QUICK_SEARCH_NS}/${RESET_PARAMS}`)
      const config: Config = {
        baseUrl: '/api/plot/quick-search/',
        rootCategoryId: CategoryId.PLOT,
        flexGrow: {
          offertype: false,
          category: true,
          location: true
        },
        hideOnMobileWhenNoSearchables: {
          category: true
        }
      }
      this.$store.commit(`${QUICK_SEARCH_NS}/${SET_CONFIG}`, config)
      await this.$store.dispatch(`${QUICK_SEARCH_NS}/loadData`)
    }
  }
})
