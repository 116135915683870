








import { defineComponent, ref, watch } from '~/utils/nuxt3-migration'

export default defineComponent({
  inheritAttrs: true,
  components: {
    VCalendarDatepicker: () =>
      import('v-calendar/lib/components/date-picker.umd')
  },
  setup(_props, { emit }) {
    const calendarRef = ref()

    function focusDate(date: Date) {
      calendarRef.value.focusDate(date)
    }

    watch(calendarRef, newCalendarRef => {
      if (newCalendarRef) {
        emit('show')
      }
    })

    return { calendarRef, focusDate }
  }
})
