












import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock'
import { SearchbarLegacySuggestion } from '~/models/search-bar'
import { mapDeps } from '~/plugins/dependency-container/utils'
import ScrollService from '~/services/scroll/ScrollService'
import SearchbarPathText from '~/components/shared/classified/search/searchbar/SearchbarPathText.vue'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: { SearchbarPathText },
  props: {
    suggestion: {
      type: Object as PropType<SearchbarLegacySuggestion>,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: true
    },
    hoverable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    ...mapDeps({
      scrollService: ScrollService
    }),
    icons() {
      return {
        history: faClock,
        search: faSearch
      }
    },
    searchIcon() {
      return this.icons.search
    },
    suggestionText() {
      return this.suggestion.name
    },
    categories() {
      return this.suggestion.categoryHumanPathParts || []
    }
  },
  watch: {
    active(val, oldVal) {
      if (oldVal === false && val === true) {
        this.$emit('suggestion-active', this.suggestionText)
        this.scrollService.scrollIfOverflownVertical(
          this.$el,
          this.$parent.$refs.suggestions
        )
      }
    }
  },
  methods: {
    suggestionClicked(e) {
      e.preventDefault()
      e.stopPropagation()

      this.$emit('clicked')
    }
  }
})
