












































import {
  defineComponent,
  onMounted,
  ref,
  toRefs,
  onBeforeUnmount,
  watch
} from '~/utils/nuxt3-migration'

import { Timeout } from '~/models/timing/timeout'
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import { useSwipe } from '~/compositions/swipe'

export default defineComponent({
  props: {
    interval: {
      type: Number,
      required: false,
      default: 0
    },
    hideArrows: {
      type: Boolean,
      required: false,
      default: false
    },
    arrowsOnHover: {
      type: Boolean,
      required: false,
      default: true
    },
    btnVariant: {
      type: String,
      default: 'black',
      required: false
    },
    showDots: {
      type: Boolean,
      required: false,
      default: true
    },
    sliderClass: {
      type: String,
      required: false,
      default: ''
    },
    sliderWrapperClass: {
      type: String,
      default: ''
    },
    indexSliderClass: {
      type: String,
      default: ''
    },
    slotsCount: {
      type: Number,
      required: true
    },
    swipeEnabled: {
      type: Boolean,
      required: false,
      default: true
    },
    activeDotClass: {
      type: String,
      default: 'tw-bg-grey-500'
    },
    dotsClass: {
      type: String,
      default: ''
    },
    externalActiveIndex: {
      type: Number,
      required: false,
      default: undefined
    }
  },
  setup(props, { emit }) {
    const {
      interval,
      slotsCount,
      swipeEnabled,
      activeDotClass,
      externalActiveIndex
    } = toRefs(props)
    const slideInterval = ref<Timeout | null>(null)
    const transitionName = ref('swipe-left')
    const activeSlide = ref(0)

    const swipeableElement = ref<HTMLElement | null>(null)

    onMounted(() => {
      setAutoSlideInterval()
    })

    onBeforeUnmount(() => {
      clearInterval(slideInterval.value)
    })

    function getDotClass(index: number) {
      return index === activeSlide.value
        ? [activeDotClass.value, 'active-dot']
        : ['tw-bg-grey-400', 'tw-cursor-pointer']
    }

    const setAutoSlideInterval = () => {
      if (interval.value) {
        // 0 means no auto slide
        clearInterval(slideInterval.value)
        if (slotsCount.value > 1) {
          slideInterval.value = setInterval(nextSlide, interval.value)
        }
      }
    }

    watch(externalActiveIndex, val => {
      onDotClick(val)
    })
    watch(activeSlide, val => {
      emit('index-change', val)
    })
    const prevSlide = () => {
      transitionName.value = 'swipe-right'
      if (activeSlide.value > 0) {
        activeSlide.value--
      } else {
        activeSlide.value = slotsCount.value - 1
      }
      setAutoSlideInterval()
    }

    const nextSlide = () => {
      transitionName.value = 'swipe-left'
      if (activeSlide.value < slotsCount.value - 1) {
        activeSlide.value++
      } else {
        activeSlide.value = 0
      }
      setAutoSlideInterval()
    }

    const { getDragging } = useSwipe(
      swipeableElement,
      nextSlide,
      prevSlide,
      swipeEnabled.value
    )

    const onDotClick = (slideToActivate: number) => {
      if (slideToActivate !== activeSlide.value) {
        transitionName.value =
          slideToActivate < activeSlide.value ? 'swipe-right' : 'swipe-left'
        activeSlide.value = slideToActivate
        setAutoSlideInterval()
      }
    }

    const isDragging = () => {
      return getDragging()
    }

    return {
      getDotClass,
      swipeableElement,
      transitionName,
      prevSlide,
      nextSlide,
      onDotClick,
      setAutoSlideInterval,
      activeSlide,
      faChevronLeft,
      faChevronRight,
      isDragging
    }
  }
})
