




import { defineComponent, PropType } from '~/utils/nuxt3-migration'
import { Icon } from '~/models/common/types'

export default defineComponent({
  props: {
    icon: {
      type: [Object, String] as PropType<Icon>,
      required: true
    }
  }
})
