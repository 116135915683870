























import { defineComponent } from '~/utils/nuxt3-migration'
import { mapGetters } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '../../../../../store/modules/shared/classifieds/search/state'
import { categoriesIconsMappings } from '~/constants/category/icon'
import { CategoryId } from '~/models/category/types'
import { ciDeals } from '~/icons/source/brand/deals'
import { ciPlot } from '~/icons/source/brand/plot'
import { ciBlackFriday } from '~/icons/source/brand/black-friday'

export default defineComponent({
  props: {
    loadedCat: {
      type: Number,
      required: true
    },
    dropdown: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      textSearchableCategories: 'getTextSearchSearchableCategories'
    }),
    allCategoreis() {
      return [
        ...this.textSearchableCategories,
        {
          id: CategoryId.PARTS,
          humanNamePlural: this.$t('parts'),
          seoUrl: '/parts/'
        },
        {
          id: CategoryId.RENTALS,
          humanNamePlural: this.$t('rentals'),
          seoUrl: '/rentals/'
        },
        {
          id: CategoryId.PLOT,
          humanNamePlural: this.$t('real estate'),
          seoUrl: '/plot/'
        },
        {
          id: CategoryId.XYMA,
          humanNamePlural: this.$t('classifieds::xyma'),
          seoUrl: '/xyma/'
        },
        {
          id: CategoryId.JOBS,
          humanNamePlural: this.$t('jobs'),
          seoUrl: '/jobs/'
        },
        {
          id: 'deals',
          humanNamePlural: this.$t('deals'),
          seoUrl: '/deals/'
        }
      ]
    }
  },
  methods: {
    getSearchableIcon(catId: number) {
      switch (catId) {
        case 'deals':
          return ciDeals
        case 'black-friday':
          return ciBlackFriday
        case CategoryId.PLOT:
          return ciPlot
        default:
          return categoriesIconsMappings[catId]
      }
    },
    onCatClick(e: Event, id: number) {
      if (this.loadedCat === id) {
        e.preventDefault()
        e.stopPropagation()
        this.$emit('close-dropdown')
      }
    }
  }
})
