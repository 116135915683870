
























import { FeedUser } from '~/models/user/types'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<FeedUser>,
      required: true
    },
    showLink: {
      type: Boolean,
      required: false,
      default: true
    },
    rowStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    feedLabel: {
      type: String,
      required: false,
      default: ''
    },
    useRouter: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    pageUrl() {
      return this.user.pageUrl
    },
    defaultAvatar() {
      return require('~/assets/img/user/default_profile.png')
    },
    imgSrc() {
      return this.user.avatar || this.defaultAvatar
    }
  },
  mounted() {
    this.$emit('image-load')
  },
  methods: {
    hrefClick(e) {
      e.preventDefault()
      e.stopPropagation()
      if (this.showLink) {
        this.userClicked(e)
      }
      return false
    },
    userClicked(evt) {
      this.$analytics.recordEvent({
        namespace: 'n_index_feed_user_profile',
        action: 'click',
        label: this.feedLabel + ' (' + this.user.name + ')'
      })

      if (evt) {
        evt.preventDefault()
        if (evt.ctrlKey) {
          window.open(this.pageUrl)
          return
        }
      }

      if (!this.useRouter) {
        window.location.href = this.pageUrl
        return
      }

      return this.$router.push({ path: this.pageUrl })
    }
  }
})
