





















































import { mapGetters, mapState } from 'vuex'
import { USER_NS } from '~/store/modules/shared/user/state'
import CCategoryIcon from '~/components/shared/configurable/category/CCategoryIcon.vue'
import { CategoryId } from '~/models/category/types'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { LinkType } from '~/models/search/types'
import { INDEX_NS } from '~/store/modules/shared/index/state'
import { AccessToggleName } from '~/models/user/access-toggles'
import FeedUser from '~/components/car/classifieds/index/feeds/sidebar/FeedUser.vue'
import FeedSideButton from '~/components/car/classifieds/index/feeds/sidebar/FeedSideButton.vue'
import PaymentsSummaryWidget from '~/components/car/classifieds/search/results/PaymentsSummaryWidget.vue'
import CNewBadge from '~/components/shared/configurable/badge/CNewBadge.vue'
import RouteGuardService from '~/services/RouteGuardService'
import CPosting from '~/components/shared/configurable/ad/CPosting.vue'
import {
  defineComponent,
  defineVue3AsyncComponent
} from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    CPosting,
    CNewBadge,
    FeedSideButton,
    FeedUser,
    AnalyticsGlimpse: defineVue3AsyncComponent(() =>
      import('~/components/shared/analytics/AnalyticsGlimpse.vue')
    ),
    PaymentsSummaryWidget,
    CCategoryIcon
  },
  data() {
    return {
      userOpen: false
    }
  },
  computed: {
    ...mapState(INDEX_NS, {
      statistics: state => state.statistics
    }),
    ...mapGetters(USER_NS, {
      userHasAccessTo: 'userHasAccessTo',
      isAdmin: 'isAdmin',
      isPayingUser: 'isPayingUser',
      userIsAnon: 'isAnon'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    }),
    showAnalyticsGlimpse() {
      if (this.isAdmin) {
        return true
      }
      if (!this.userHasAccessTo(AccessToggleName.USER_ANALYTICS)) {
        return false
      }
      return Boolean(this.statistics)
    },
    userCanTrade() {
      const routeGuardService = this.$dep(RouteGuardService)
      return routeGuardService.userCanTrade() || this.userIsAnon
    },
    catBtns() {
      return [
        {
          catId: CategoryId.CARS,
          label: this.$t('cars::vehicles'),
          link: '/quick-search/vehicles/cars',
          useNuxtLink: false
        },
        {
          catId: CategoryId.BIKES,
          label: this.$t('bikes::vehicles'),
          link: '/quick-search/vehicles/bikes',
          useNuxtLink: false
        },
        {
          catId: CategoryId.COMMERCIAL,
          label: this.$t('commercials::vehicles'),
          link: '/quick-search/vehicles/commercials',
          useNuxtLink: false
        },
        {
          catId: CategoryId.CAMPERS,
          label: this.$t('campers::vehicles'),
          link: '/quick-search/vehicles/campers',
          useNuxtLink: false
        },
        {
          catId: CategoryId.BOATS,
          label: this.$t('boats::vehicles'),
          link: '/quick-search/vehicles/boats',
          useNuxtLink: false
        },
        {
          catId: CategoryId.BICYCLES,
          label: this.$t('bicycles::vehicles'),
          link: '/quick-search/vehicles/bicycles',
          useNuxtLink: false
        },
        {
          catId: CategoryId.HOBBY,
          label: this.$t('hobby::vehicles'),
          link: '/quick-search/vehicles/hobby',
          useNuxtLink: false
        },
        {
          catId: CategoryId.PARTS,
          label: this.$t('parts::vehicles'),
          link: '/quick-search/parts/for-cars',
          useNuxtLink: false
        },
        {
          type: LinkType.RENTALS,
          catId: CategoryId.RENTALS,
          label: this.$t('rentals'),
          link: '/quick-search/rentals',
          useNuxtLink: true
        },
        {
          type: LinkType.REAL_ESTATE,
          catId: CategoryId.PLOT,
          label: this.$t('real estate'),
          link: '/quick-search/plot',
          useNuxtLink: true
        },
        {
          type: LinkType.XYMA,
          catId: CategoryId.XYMA,
          label: this.$t('classifieds::xyma'),
          link: '/quick-search/xyma',
          useNuxtLink: false
        },
        {
          catId: CategoryId.CLASSIFIEDS,
          label: this.$t('deals'),
          link: '/deals/',
          useNuxtLink: true,
          type: LinkType.DEALS
        },
        {
          type: LinkType.JOBS,
          catId: CategoryId.JOBS,
          label: this.$t('jobs'),
          link: '/jobs/',
          useNuxtLink: true
        },
        {
          type: LinkType.TRADES,
          label: this.$t('trades'),
          link: this.$router.resolve({ name: '__trades' }).href,
          useNuxtLink: true,
          hide: !this.userCanTrade
        }
      ]
    }
  },
  methods: {
    categoryLinkClicked(e, cat) {
      e.preventDefault()
      this.$analytics.recordEvent({
        namespace: 'n_index_category_link_click',
        action: 'click',
        label: cat.label
      })

      if (cat.useNuxtLink) {
        return this.$router.push({
          path: cat.link
        })
      } else {
        window.location.href = cat.link
      }
    }
  }
})
