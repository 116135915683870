



















import { computed, defineComponent, ref, toRefs } from '~/utils/nuxt3-migration'
import AdViewService from '~/services/ads/AdViewService'
import { useDeps } from '~/compositions/dependency-container'
import CAdContainer from '~/components/shared/configurable/ad/container/CAdContainer.vue'
import { InHouseSizes } from '~/constants/ads/inhouse'
import InHouseService from '~/services/ads/InHouseService'
import { AdItem } from '~/models/advertising/types'
import RenderedInHouseItem from '~/components/shared/advertising/RenderedInHouseItem.vue'
import { useLogger } from '~/compositions/logger'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import { useNamespacedStore } from '~/compositions/store'
import { Facet } from '~/models/search/types'
import {
  CLASSIFIED_VIEW_NS,
  ClassifiedViewState
} from '~/store/modules/shared/classifieds/view/state'
import { CategoryId } from '~/models/category/types'

export default defineComponent({
  components: { RenderedInHouseItem, CAdContainer },
  props: {
    unit: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { unit } = toRefs(props)
    const slot = ref<AdItem | null>(null)
    const loading = ref<boolean>(false)
    const logger = useLogger()

    const { getters: searchGetters, state: searchState } = useNamespacedStore<
      SearchState
    >(CLASSIFIED_SEARCH_NS)

    const { state: classifiedViewState } = useNamespacedStore<
      ClassifiedViewState
    >(CLASSIFIED_VIEW_NS)

    const selectedSearchFacets = computed(() =>
      searchGetters('getSelectedFacets')
    )

    const unitDetails = computed(() => {
      return InHouseSizes[unit.value] || {}
    })

    const [adViewService, inHouseService] = useDeps(
      AdViewService,
      InHouseService
    )
    const containerSize = computed(() => {
      if (unit.value === 'in_house_search_dt_top_gal') {
        return [250, 250]
      }
      return adViewService.getMaxSizeDimensions(unitDetails.value.sizes || [])
    })
    const sizeMapping = computed(() => {
      return unitDetails.value.sizesMappings || []
    })

    const categories = computed(() => {
      if (unit.value.startsWith('in_house_search')) {
        if (searchState.categoryIds?.length) {
          return searchState.categoryIds.filter(
            c => c !== CategoryId.CLASSIFIEDS // no need to send this one
          )
        }
      } else if (unit.value.startsWith('in_house_cls')) {
        // in classified view
        return classifiedViewState.classified?.category_ids || []
      }

      return []
    })

    const makes = computed(() => {
      if (unit.value.startsWith('in_house_search')) {
        // in search
        const makeFacet = selectedSearchFacets.value.find(
          (f: Facet) => f.name === 'makemodel'
        )
        if (makeFacet) {
          return makeFacet.values?.selected?.makes || []
        } else {
          const forMakeModelFacet = selectedSearchFacets.value.find(
            (f: Facet) => f.name === 'for_makemodels'
          )

          if (forMakeModelFacet) {
            return (
              forMakeModelFacet.values?.params
                ?.filter(p => p[0] === 'makes_ids')
                .map(fp => fp[1]) || []
            )
          }
        }
      } else if (unit.value.startsWith('in_house_cls')) {
        // in classified view
        const classifiedMake = classifiedViewState.adsTargetings?.find(
          t => t.name === 'make'
        )
        if (classifiedMake) {
          return [classifiedMake.value]
        }
      }

      return []
    })

    const fetchEntry = async () => {
      try {
        loading.value = true
        const { item } = await inHouseService.fetchInHouseEntry(
          unit.value,
          makes.value,
          categories.value
        )
        if (!item) {
          emit('no-fetch')
          return
        }
        slot.value = item
      } catch (e) {
        logger.captureError(e)
      } finally {
        loading.value = false
      }
    }

    const refresh = () => {
      // this.googleAdsService.refreshSlots([slot.value])
      slot.value = null
      loading.value = false
    }

    const recordImpression = async () => {
      try {
        if (slot.value?.id) {
          await inHouseService.recordImpression(slot.value.id)
        }
      } catch (e) {
        logger.captureError(e)
      }
    }
    const triggerLoad = (visible: boolean) => {
      if (!slot.value && visible && !loading.value) {
        fetchEntry()
      }
    }

    return {
      fetchEntry,
      refresh,
      containerSize,
      sizeMapping,
      unitDetails,
      slot,
      recordImpression,
      triggerLoad,
      selectedSearchFacets,
      makes
    }
  }
})
