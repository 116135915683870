











import { defineComponent } from '~/utils/nuxt3-migration'
// @ts-ignore
import countTo from 'vue-count-to'
export default defineComponent({
  components: { countTo },
  props: {
    startVal: {
      type: Number,
      required: true
    },
    endVal: {
      type: Number,
      required: true
    },
    duration: {
      type: Number,
      default: 3000
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    separator: {
      type: String,
      default: undefined
    },
    decimals: {
      type: Number,
      required: false,
      default: 0
    }
  }
})
