










import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    slotWrapperClass: { type: [String, Array, Object], default: () => null }
  }
})
