













import {
  defineComponent,
  computed,
  onMounted,
  ref,
  useRoute,
  useRouter,
  PropType
} from '~/utils/nuxt3-migration'
import useQuickSearchMenu from '~/compositions/quick-search-menu/quick-search-menu'
import { useModalToggle } from '~/compositions/modal-toggle'
import QuickSearchMobileMenu from '~/components/car/categories-and-filters/QuickSearchMobileMenu.vue'
import { CategoryId } from '~/models/category/types'

export default defineComponent({
  components: { QuickSearchMobileMenu },
  props: {
    selectedSearchbarCategory: {
      type: Number as PropType<CategoryId>,
      required: true
    }
  },
  setup(_, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const { topLevelCategories } = useQuickSearchMenu()
    const {
      setHash,
      modalOpen,
      setAfterModalCloseCallback,
      modalClose
    } = useModalToggle(() => emit('close-menu'))

    const linkToNavigate = ref('')

    const mobileMenuHeight = computed(() => {
      if (topLevelCategories.value.some(cat => cat.hasOpenMenu))
        return {
          'max-menu-height': true
        }
      return {
        'min-menu-height': true
      }
    })

    function afterModalClose() {
      if (linkToNavigate.value) {
        const link = linkToNavigate.value
        linkToNavigate.value = ''

        // add any analytic events here

        if (router.resolve({ path: link }).route.name !== route.value.name) {
          return router.push({
            path: link
          })
        }
      }
    }

    const closeMenu = () => {
      modalClose(false)
    }

    const closeMenuOnNavigate = computed(() => {
      // enable if we ever want to close the menu after navigation (like mobile menu)
      return false
    })

    const menuNavigate = (e: Event, link: string) => {
      if (e) {
        e.preventDefault()
      }
      linkToNavigate.value = link

      // adding !closeMenuOnNavigate.value here so it goes to modalClose with and only removes modal classes
      // without remove the hash (we need it in order to reopen the modal when user clicks back from destinations)
      modalClose(!closeMenuOnNavigate.value, !closeMenuOnNavigate.value)

      if (!closeMenuOnNavigate.value) {
        afterModalClose()
      }
    }

    onMounted(() => {
      setHash('quick-search')
      modalOpen()
      setAfterModalCloseCallback(afterModalClose)
    })

    return { mobileMenuHeight, closeMenu, menuNavigate, closeMenuOnNavigate }
  }
})
