import {
  ref,
  nextTick,
  onMounted,
  onBeforeUnmount
} from '~/utils/nuxt3-migration'
import { useLogger } from '~/compositions/logger'
import { Ref } from '@vue/composition-api'

export function useSwipe(
  swipeableElement: Ref,
  swipeLeftCallback: Function,
  swipeRightCallback: Function,
  swipeEnabled: boolean = true
) {
  const initialX = ref<number | null>(null)
  const initialY = ref<number | null>(null)
  const swapDirection = ref<string | null>(null)
  const dragging = ref<boolean>(false)
  const diffX = ref<number>(0)
  const diffY = ref<number>(0)
  const isTouch = ref<boolean>(false)
  const listenersAdded = ref<boolean>(false)
  const enabled = ref<boolean>(swipeEnabled)
  const logger = useLogger()

  onMounted(() => {
    addSwipeListeners()
  })

  onBeforeUnmount(() => {
    removeSwipeListeners()
  })

  const addSwipeListeners = async () => {
    await nextTick()
    if (enabled.value && !listenersAdded.value) {
      if (
        swipeableElement.value &&
        swipeableElement.value instanceof HTMLElement
      ) {
        swipeableElement.value.addEventListener('touchstart', startTouch, {
          passive: true
        })
        swipeableElement.value.addEventListener('touchmove', moveInput, {
          passive: true
        })
        swipeableElement.value.addEventListener('mousedown', startInput, {
          passive: true
        })
        swipeableElement.value.addEventListener('mousemove', moveInput, {
          passive: true
        })

        swipeableElement.value.addEventListener('selectstart', disableSelect)

        listenersAdded.value = true
      } else {
        logger.captureError(new Error('No swipeableElement found'))
      }
    }
  }

  const removeSwipeListeners = () => {
    removeDocumentListeners()
    listenersAdded.value = false
  }

  const removeElementListeners = () => {
    if (
      swipeableElement.value &&
      swipeableElement.value instanceof HTMLElement
    ) {
      swipeableElement.value.removeEventListener('touchstart', startTouch)
      swipeableElement.value.removeEventListener('touchmove', moveInput)
      swipeableElement.value.removeEventListener('mousedown', startInput)
      swipeableElement.value.removeEventListener('mousemove', moveInput)

      swipeableElement.value.removeEventListener('selectstart', disableSelect)
    }
  }

  const removeDocumentListeners = () => {
    if (process.client) {
      document.removeEventListener('mouseup', stopInput)
      document.removeEventListener('touchend', stopInput)
    }
  }

  const enableSwipe = () => {
    if (!enabled.value) {
      enabled.value = true
      addSwipeListeners()
    }
  }

  const disableSwipe = () => {
    if (enabled.value) {
      enabled.value = false
      removeSwipeListeners()
      removeElementListeners()
    }
  }
  const startTouch = (e: MouseEvent | TouchEvent) => {
    isTouch.value = true

    startInput(e)
  }

  const startInput = (e: MouseEvent | TouchEvent) => {
    if (!e) {
      return
    }
    if (isTouch.value) {
      document.addEventListener('touchend', stopInput, {
        passive: true
      })
    } else {
      document.addEventListener('mouseup', stopInput, {
        passive: true
      })
    }

    if (isTouch.value && e instanceof TouchEvent) {
      initialX.value = e.touches[0].clientX
      initialY.value = e.touches[0].clientY
    } else if (e instanceof MouseEvent) {
      initialX.value = e.clientX
    }
  }
  const moveInput = (e: MouseEvent | TouchEvent) => {
    if (initialX.value === null || !e) {
      return
    }

    let currentX = null
    let currentY = null

    if (isTouch.value && e instanceof TouchEvent) {
      currentX = e.touches[0].clientX
      currentY = e.touches[0].clientY
    } else if (e instanceof MouseEvent) {
      currentX = e.clientX
    }

    if (currentX !== null) {
      diffX.value = initialX.value - currentX
    }
    if (initialY.value !== null && currentY !== null) {
      diffY.value = initialY.value - currentY
    }

    if (Math.abs(diffX.value) > Math.abs(diffY.value)) {
      // we scroll on the x-axis
      dragging.value = true

      e.stopImmediatePropagation()
      e.stopPropagation()
      // e.preventDefault()

      // 10 is a bit of offset, normally it's 0
      if (diffX.value > 10) {
        // swiped left
        swapDirection.value = 'left'
      } else if (diffX.value < -10) {
        // swiped right
        swapDirection.value = 'right'
      } else {
        swapDirection.value = null
        dragging.value = false // so that it gets clicked instantly
      }
    }
  }
  const stopInput = (e: Event) => {
    if (swapDirection.value === 'right') {
      onSwipeRight()
    } else if (swapDirection.value === 'left') {
      onSwipeLeft()
    }

    if (hasSomeParentWithClass(e.target as HTMLElement, 'swipable')) {
      // using setTimeout here because nextTick was pretty buggy in some cases
      setTimeout(() => {
        dragging.value = false
      }, 10)
    } else {
      dragging.value = false
    }

    if (isTouch.value) {
      dragging.value = false
    }

    swapDirection.value = null
    initialX.value = null
    initialY.value = null
    diffX.value = 0
    diffY.value = 0
    isTouch.value = false

    removeDocumentListeners()
  }
  const disableSelect = (e: Event) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const onSwipeLeft = () => {
    swipeLeftCallback()
  }
  const onSwipeRight = () => {
    swipeRightCallback()
  }
  const hasSomeParentWithClass = (
    element: HTMLElement | null,
    classname: string
  ): boolean => {
    if (!element) {
      return false
    }
    const classString = '' + element.className

    if (classString && classString.split(' ').includes(classname)) {
      return true
    }
    return Boolean(
      element.parentNode &&
        hasSomeParentWithClass(element.parentNode as HTMLElement, classname)
    )
  }

  const getDragging = () => {
    return dragging.value
  }

  return {
    addSwipeListeners,
    removeSwipeListeners,
    removeDocumentListeners,
    startTouch,
    startInput,
    moveInput,
    stopInput,
    disableSelect,
    onSwipeRight,
    onSwipeLeft,
    hasSomeParentWithClass,
    enableSwipe,
    disableSwipe,
    getDragging
  }
}
