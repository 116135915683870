


















import { RecordingStatus } from '~/constants/speech-recognition'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    maxLength: {
      type: Number,
      default: 70,
      required: false
    }
  },
  data: () => ({
    SpeechRecognitionAPI: null,
    status: RecordingStatus.IDLE,
    recognizer: null,
    finalTranscript: ''
  }),
  mounted() {
    this.SpeechRecognitionAPI =
      window.webkitSpeechRecognition || window.SpeechRecognition

    if (this.SpeechRecognitionAPI) {
      this.$emit('hasMic', true)
    }
  },
  methods: {
    // https://developer.mozilla.org/en-US/docs/Web/API/SpeechRecognition
    record() {
      if (this.status === RecordingStatus.RECORDING && this.recognizer) {
        this.recognizer.stop()
        this.recognizer = null
      } else {
        this.status = RecordingStatus.RECORDING
        this.recognizer = new this.SpeechRecognitionAPI()
        this.recognizer.interimResults = true
        this.recognizer.maxAlternatives = 1
        this.recognizer.continuous = false
        this.setupListeners(this.recognizer)

        this.recognizer.start()
      }
    },
    setupListeners(recognizer) {
      recognizer.onresult = event => {
        let interimTranscript = ''
        for (
          let i = event.resultIndex, len = event.results.length;
          i < len;
          i++
        ) {
          const transcript = event.results[i][0].transcript
          if (event.results[i].isFinal) {
            this.finalTranscript += transcript
          } else {
            interimTranscript += transcript
          }

          if (
            interimTranscript.length > this.maxLength ||
            this.finalTranscript.length > this.maxLength
          ) {
            this.recognizer.stop()
          }
        }
        this.$emit('interimTranscript', interimTranscript)
      }
      recognizer.onend = () => {
        this.onStop()
      }
    },
    onStop() {
      this.$emit('finalTranscript', this.finalTranscript)
      this.finalTranscript = ''
      this.status = RecordingStatus.IDLE
    }
  },
  computed: {
    btnClass() {
      if (this.status === RecordingStatus.IDLE) {
        return 'btn-grey-50'
      } else if (this.status === RecordingStatus.RECORDING) {
        return 'btn-recording'
      }
      return 'tw-bg-danger tw-border tw-border-solid tw-border-danger tw-text-white'
    }
  },
  watch: {
    status(val) {
      this.$emit('recordingStatus', val === RecordingStatus.RECORDING)
    }
  }
})
