



















import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { computed, defineComponent, toRefs } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { small } = toRefs(props)

    const icons = {
      close: faTimes
    }

    function onCloseButtonClick(event: PointerEvent) {
      emit('close', event)
    }
    function onTagClick(event: PointerEvent) {
      emit('click', event)
    }

    const tagClasses = computed(() => {
      const c = []
      if (small.value) {
        c.push('tw-text-sm')
      }
      return c
    })

    return { icons, onCloseButtonClick, tagClasses, onTagClick }
  }
})
