import { CIcon } from '~/icons/types'

export const ciMagnifyingGlass: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M10.5 4.5a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm-9 6a9 9 0 1 1 16.336 5.215l4.225 4.224a1.5 1.5 0 0 1-2.122 2.122l-4.224-4.225A9 9 0 0 1 1.5 10.5Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'magnifying-glass',
  type: 'solid'
}
