


























import {
  computed,
  defineComponent,
  onBeforeUnmount,
  PropType,
  ref,
  toRefs,
  useStore
} from '~/utils/nuxt3-migration'
import { AdItem, InHouseUnitDetails } from '~/models/advertising/types'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import { Timeout } from '~/models/timing/timeout'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import InHouseTemplate from '~/components/shared/advertising/templates/InHouseTemplate.vue'
import InHouseService from '~/services/ads/InHouseService'
import { useDep } from '~/compositions/dependency-container'

const EMPTY_IMAGE_URI =
  'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
export default defineComponent({
  components: { InHouseTemplate, CImg },
  props: {
    item: {
      type: Object as PropType<AdItem>,
      required: true
    },
    details: {
      type: Object as PropType<InHouseUnitDetails>,
      required: true
    },
    recImpression: {
      type: Boolean,
      required: false,
      default: true
    },
    unit: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { details, item, recImpression } = toRefs(props)
    const impressionTimeout = ref<Timeout | null>(null)
    const impressionRecorded = ref<boolean>(false)
    const visible = ref<boolean>(false)

    const store = useStore()
    const inHouseService = useDep(InHouseService)

    const unsubscribe = store.subscribeAction(action => {
      if (action.type === CLASSIFIED_SEARCH_NS + '/refreshAds') {
        refresh()
      }
    })

    const mainSrc = computed(() => {
      return details.value.sizes.length
        ? getSrcForSize(
            Array.isArray(details.value.sizes[0])
              ? inHouseService.getSizeForCurrentResolution(
                  details.value.sizes,
                  details.value.sizesMappings
                )
              : details.value.sizes
          )
        : EMPTY_IMAGE_URI
    })

    onBeforeUnmount(() => {
      unsubscribe()
      if (impressionTimeout.value) {
        clearTimeout(impressionTimeout.value)
      }
    })

    const getSrcForSize = (mapping: number[]) => {
      return item.value[`banner_${mapping[0]}_${mapping[1]}_url`]
    }

    const formattedSources = computed(() => {
      if (details.value.sizesMappings?.length) {
        return details.value.sizesMappings.map(mapping => ({
          width: mapping[0][0],
          src: mapping[1][0]?.length
            ? getSrcForSize(mapping[1][0])
            : EMPTY_IMAGE_URI
        }))
      }
      return []
    })

    const onHalfVisible = (isVisible: boolean) => {
      visible.value = isVisible
      if (
        !impressionRecorded.value &&
        isVisible &&
        recImpression.value &&
        !impressionTimeout.value
      ) {
        impressionTimeout.value = setTimeout(() => {
          if (visible.value) {
            emit('record-impression')
            impressionRecorded.value = true
            if (impressionTimeout.value) {
              clearTimeout(impressionTimeout.value)
            }
          }
        }, 1000)
      }
    }

    const refresh = () => {
      impressionTimeout.value = null
      impressionRecorded.value = false
      visible.value = false
    }

    return {
      mainSrc,
      formattedSources,
      onHalfVisible,
      visible,
      inHouseService
    }
  }
})
