



































import { FeedCategory } from '~/models/user/types'
import { formatNumber } from '~/utils/number'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    category: {
      type: Object as PropType<FeedCategory>,
      required: true
    },
    showLink: {
      type: Boolean,
      required: false,
      default: true
    },
    rowStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    linkDraggable: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: Number,
      required: false,
      default: null
    },
    width: {
      type: Number,
      required: false,
      default: null
    },
    feedLabel: {
      type: String,
      required: false,
      default: ''
    },
    useRouter: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    categoryUrl() {
      return this.category.targetUrl
    },
    formattedCount() {
      if (
        this.category.classifiedsCount ||
        this.category.classifiedsCount === 0
      ) {
        return formatNumber(this.category.classifiedsCount)
      }
      return null
    }
  },
  mounted() {
    this.$emit('image-load')
  },
  methods: {
    hrefClick(e) {
      e.preventDefault()
      e.stopPropagation()
      if (this.showLink) {
        this.categoryClicked(e)
      }
      return false
    },
    categoryClicked(evt) {
      this.$analytics.recordEvent({
        namespace: 'n_index_feed_categories',
        action: 'click',
        label: this.feedLabel + ' (' + this.category.title + ')'
      })

      if (evt) {
        evt.preventDefault()
        if (evt.ctrlKey) {
          window.open(this.categoryUrl)
          return
        }
      }

      if (!this.useRouter) {
        window.location.href = this.categoryUrl
        return
      }

      return this.$router.push({ path: this.categoryUrl })
    }
  }
})
