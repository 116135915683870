










import { computed, defineComponent, toRefs } from '~/utils/nuxt3-migration'
import { facetProps } from '~/constants/quick-search'
import PickupLocationQuickSearchHandler from '~/components/shared/quick-search/facets/handlers/PickupLocationQuickSearchHandler.vue'
import CategoryQuickSearchHandler from '~/components/shared/quick-search/facets/handlers/CategoryQuickSearchHandler.vue'
import PeriodQuickSearchHandler from '~/components/shared/quick-search/facets/handlers/PeriodQuickSearchHandler.vue'
import OfferTypeQuickSearchHandler from '~/components/shared/quick-search/facets/handlers/OfferTypeQuickSearchHandler.vue'
import LocationQuickSearchHandler from '~/components/shared/quick-search/facets/handlers/LocationQuickSearchHandler.vue'

export default defineComponent({
  props: { ...facetProps },
  setup(props) {
    const { name } = toRefs(props)

    const handlerComponent = computed(() => {
      switch (name.value) {
        case 'pickup_location': {
          return PickupLocationQuickSearchHandler
        }
        case 'category': {
          return CategoryQuickSearchHandler
        }
        case 'rental_period': {
          return PeriodQuickSearchHandler
        }
        case 'offertype': {
          return OfferTypeQuickSearchHandler
        }
        case 'location': {
          return LocationQuickSearchHandler
        }
        default: {
          return null
        }
      }
    })

    return { handlerComponent }
  }
})
