


























import {
  computed,
  defineComponent,
  ref,
  useFetch,
  useRouter
} from '~/utils/nuxt3-migration'
import PromotionsBanner from '~/components/car/banners/PromotionsBanner.vue'
import CContentSlider from '~/components/shared/configurable/CContentSlider.vue'
import { useNamespacedStore } from '~/compositions/store'
import {
  USER_AGENT_NS,
  UserAgentState
} from '~/store/modules/shared/userAgent/state'
import { shuffleArray } from '~/utils/array'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { useAnalytics } from '~/compositions/analytics'
import FinanceBanner from '~/components/car/banners/FinanceBanner.vue'
import AuditsBanner from '~/components/car/banners/AuditsBanner.vue'
import LeasingBanner from '~/components/car/banners/LeasingBanner.vue'
import WantedPartsBanner from '~/components/car/banners/WantedPartsBanner.vue'
import RouteGuardService from '~/services/RouteGuardService'
import { useDep } from '~/compositions/dependency-container'

export default defineComponent({
  components: {
    CContentSlider,
    PromotionsBanner,
    FinanceBanner,
    AuditsBanner,
    LeasingBanner,
    WantedPartsBanner
  },
  setup() {
    const router = useRouter()

    const isMobile = computed(() => userAgentGetters('isMobile'))
    const sliderRef = ref(null)
    const { getters: userAgentGetters } = useNamespacedStore<UserAgentState>(
      USER_AGENT_NS
    )
    const routeGuardService = useDep(RouteGuardService)
    const slides = ref<string[]>([])
    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)
    const isSingleOrAnon = computed(() => userGetters('isSingleOrAnon'))
    const slideClasses = isMobile.value ? '!tw-h-[9.375rem]' : '!tw-h-40'

    const analytics = useAnalytics()
    const bannerClick = (
      bannerName: string,
      {
        event,
        path,
        dontUseRouter
      }: { event: any; path: string; dontUseRouter?: boolean }
    ) => {
      if (!path || sliderRef.value?.isDragging()) {
        return
      }

      analytics.recordEvent({
        namespace: 'n_index_banner_click',
        action: 'click',
        label: bannerName
      })

      if (event) {
        event.preventDefault()
        if (event.ctrlKey) {
          window.open(path)
          return
        }
      }

      const routeExists = Boolean(router.resolve({ path }).route.matched.length)
      if (!routeExists || dontUseRouter) {
        window.location.href = path
        return
      }

      return router.push({ path }).catch(_e => {})
    }

    useFetch(() => {
      const dynamicBanner = routeGuardService.userHasAccessToWantedParts()
        ? 'WantedPartsBanner'
        : 'LeasingBanner'
      const slidesArray = [
        'FinanceBanner',
        'AuditsBanner',
        'PromotionsBanner',
        dynamicBanner
      ]

      slides.value = [...shuffleArray(slidesArray)]
    })

    return {
      sliderRef,
      bannerClick,
      slideClasses,
      isMobile,
      slides,
      isSingleOrAnon
    }
  }
})
