


























import { mapGetters, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { resolveCategory } from '~/utils/category'
import { categoryInAllTexts } from '~/constants/category'
import CButtonGroup from '~/components/shared/configurable/button/CButtonGroup.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    CButtonGroup
  },
  props: {
    inCategory: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      inDealsSearch: 'inDealsSearch'
    }),
    ...mapState({
      rootCategory: state => state.classifieds.search.config.rootParams.category
    }),
    inAllDisplayText() {
      if (this.inDealsSearch) {
        return this.$t('in deals')
      }
      if (this.rootCategory) {
        return this.$t(resolveCategory(categoryInAllTexts, this.rootCategory))
      }
      return null
    }
  }
})
