













































































































































import {
  faChevronDown,
  faChevronRight,
  faStream
} from '@fortawesome/free-solid-svg-icons'
import { formatNumber } from '~/utils/number'
import { mapActions, mapGetters, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import CTransitionExpand from '~/components/shared/configurable/expand/CTransitionExpand.vue'
import { ciCredit } from '~/icons/source/solid/credit'
import { defineComponent } from '~/utils/nuxt3-migration'

interface Data {
  showHistory: boolean
}

export default defineComponent({
  components: { CTransitionExpand },
  props: {
    inSidebar: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    this.loadPaidSummary()
  },
  data(): Data {
    return {
      showHistory: false
    }
  },
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      currentRoute: state => state.currentRoute
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      summary: 'getPaidSummary'
    }),
    icons() {
      return {
        chevronRight: faChevronRight,
        chevronDown: faChevronDown,
        credit: ciCredit,
        history: faStream
      }
    },
    formattedTotalDailyCost() {
      if (
        this.summary &&
        (this.summary.totalDailyCost || this.summary.totalDailyCost === 0)
      ) {
        if (this.summary.totalDailyCost !== 0) {
          return formatNumber(this.summary.totalDailyCost)
        }
        return this.summary.totalDailyCost
      }
      return null
    },
    totalLink() {
      if (
        this.summary &&
        this.summary.totalClassifieds &&
        (this.inSidebar || this.failureQuery)
      ) {
        return this.summary.totalClassifieds.seoUrl
      }
      return null
    },
    disabledLink() {
      if (
        this.summary &&
        this.summary.totalDeactivatedClassifieds &&
        (this.inSidebar || !this.failureQuery)
      ) {
        return this.summary.totalDeactivatedClassifieds.seoUrl
      }
      return null
    },
    failureQuery() {
      if (this.currentRoute) {
        const query = this.currentRoute.query
        return query.deactivated
      }
      return false
    },
    totalClassifiedsCount() {
      if (this.summary?.totalClassifieds?.count) {
        return formatNumber(this.summary.totalClassifieds.count)
      }

      return null
    }
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      loadPaidSummary: 'loadPaidSummary'
    }),
    formatCredits(c) {
      return formatNumber(c)
    }
  }
})
