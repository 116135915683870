


























import { ciMagnifyingGlass } from '~/icons/source/solid/magnifying-glass'
import { ciPlus } from '~/icons/source/regular/plus'
import { Icon } from '~/models/icons/types'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    link: { type: String, default: null },
    buttonLabel: { type: String, default: null },
    header: { type: String, default: null },
    subtitle: { type: String, default: null },
    icon: { type: Object as PropType<Icon>, default: null },
    variant: { type: String, default: 'info' }
  },
  setup() {
    return {
      ciMagnifyingGlass,
      ciPlus
    }
  }
})
