import { CIcon } from '~/icons/types'

export const ciChevronLeft: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M7.19 13.06a1.5 1.5 0 0 1 0-2.12l7.5-7.5a1.5 1.5 0 0 1 2.12 2.12L10.372 12l6.44 6.44a1.5 1.5 0 0 1-2.122 2.12l-7.5-7.5Z',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'chevron-left',
  type: 'regular'
}
