
















import { computed, defineComponent, PropType } from '~/utils/nuxt3-migration'
import CBadge from '~/components/shared/configurable/badge/CBadge.vue'

export default defineComponent({
  components: { CBadge },
  props: {
    position: {
      type: String as PropType<'right' | undefined>,
      default: undefined
    }
  },
  setup(props) {
    const badgeClasses = computed(() => {
      const classes = []

      if (props.position === 'right') {
        classes.push(
          'tw-absolute tw-z-10 tw-left-auto tw-bottom-auto tw-top-auto tw-right-2'
        )
      }

      return classes
    })

    return { badgeClasses }
  }
})
