import { CIcon } from '~/icons/types'

export const ciAngleRight: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M15.707 11.293a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414-1.414L13.586 12 9.293 7.707a1 1 0 0 1 1.414-1.414l5 5Z',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'angle-right',
  type: 'regular'
}
