












import { NotificationEvent } from '~/models/notification-center/types'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'
import NotificationEntry from '~/components/shared/configurable/notification-center/NotificationEntry.vue'

export default defineComponent({
  components: {
    NotificationEntry
  },
  props: {
    notifications: {
      type: Array as PropType<NotificationEvent[]>,
      required: true
    },
    displayInPage: { type: Boolean, default: false }
  }
})
