












































































import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '~/utils/nuxt3-migration'
import {
  AdItem,
  InHouseUnitDetails
} from '../../../../models/advertising/types'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import { useDep } from '~/compositions/dependency-container'
import InHouseService from '~/services/ads/InHouseService'

export default defineComponent({
  components: { CImg },
  props: {
    item: {
      type: Object as PropType<AdItem>,
      required: true
    },
    details: {
      type: Object as PropType<InHouseUnitDetails>,
      required: true
    },
    noShrink: {
      type: Boolean,
      required: false,
      default: false
    },
    inPosting: {
      type: Boolean,
      required: false,
      default: false
    },
    unit: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    const { details, item, inPosting, noShrink, unit } = toRefs(props)

    const inHouseService = useDep(InHouseService)

    const selectedAdSize = computed(() => {
      return Array.isArray(details.value.sizes[0])
        ? inHouseService.getSizeForCurrentResolution(
            details.value.sizes,
            details.value.sizesMappings
          )
        : details.value.sizes
    })

    const width = computed(() => {
      return selectedAdSize.value[0]
    })
    const height = computed(() => {
      return selectedAdSize.value[1]
    })

    const primaryBg = computed(() => {
      switch (item.value.designType) {
        case 'template2': {
          return 'tw-bg-grey-800'
        }
        case 'template1':
        default: {
          return 'tw-bg-grey-300'
        }
      }
    })
    const borderColor = computed(() => {
      switch (item.value.designType) {
        case 'template2': {
          return 'tw-border-grey-100'
        }
        case 'template1':
        default: {
          return 'tw-border-grey-50'
        }
      }
    })
    const textColorClass = computed(() => {
      switch (item.value.designType) {
        case 'template2': {
          return 'tw-text-white'
        }
        case 'template1':
        default: {
          return 'tw-text-grey-800'
        }
      }
    })

    const sizeString = computed(() => {
      return `${width.value}_${height.value}`
    })

    const textSizeClass = computed(() => {
      if (
        sizeString.value === '970_250' &&
        (inPosting.value || noShrink.value)
      ) {
        return 'tw-text-4xl'
      }
      return ['320_100', '728_90'].includes(sizeString.value)
        ? 'tw-text-sm'
        : 'tw-text-base'
    })
    const btnTextSizeClass = computed(() => {
      return ['320_100', '728_90'].includes(sizeString.value)
        ? 'tw-text-sm'
        : 'tw-text-base'
    })
    const gapClass = computed(() => {
      if (
        sizeString.value === '970_250' &&
        (inPosting.value || noShrink.value)
      ) {
        return 'tw-gap-4'
      }
      return ['320_100'].includes(sizeString.value) ? 'tw-gap-1' : 'tw-gap-2'
    })

    const imageContainerClasses = computed(() => {
      if (sizeString.value === '300_250') {
        return 'tw-flex-1 tw-backdrop-blur-none tw-rounded-md tw-p-2 tw-bg-cover tw-bg-center tw-relative'
      }
      return ''
    })

    const imgWidth = computed(() => {
      if (inPosting.value) {
        switch (sizeString.value) {
          case '320_100':
            return '92px'

          case '728_90':
            return '82px'

          case '970_250':
            return '242px'
          default:
            return undefined
        }
      }
      return undefined
    })
    const appliedStyle = computed(() => {
      if (inPosting.value) {
        if (unit.value === 'in_house_search_dt_top_gal') {
          return {
            width: `250px`,
            height: `250px`
          }
        }
        return {
          width: `${width.value}px`,
          height: `${height.value}px`
        }
      }
      return {
        maxWidth: `${width.value}px`,
        maxHeight: `${height.value}px`,
        aspectRatio: `${width.value}/${height.value}`
      }
    })
    return {
      width,
      height,
      selectedAdSize,
      primaryBg,
      textColorClass,
      sizeString,
      borderColor,
      textSizeClass,
      btnTextSizeClass,
      gapClass,
      imageContainerClasses,
      appliedStyle,
      imgWidth
    }
  }
})
