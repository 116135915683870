
























































import { defineComponent } from '~/utils/nuxt3-migration'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineComponent({
  components: { CImg },
  i18n: defineComponentTranslations({
    make_your_classified_first: {
      en:
        'Make<br> <em><i>your</i></em> <strong><i>classified</i></strong> <strong><i>first</i></strong>!',
      el:
        'Κάνε την <br> <strong><i>αγγελία</i></strong> <em><i>σου</i></em><br> <strong><i>πρώτη</i></strong>!'
    }
  })
})
