




























import { computed, defineComponent, ref, toRefs } from '~/utils/nuxt3-migration'
import { facetProps } from '~/constants/quick-search'
import { useI18n } from '~/compositions/i18n'
import { addYears, format } from '~/utils/date'
import { useQuickSearchParams } from '~/compositions/quick-search/useQuickSearchParams'
import CDatePicker from '~/components/shared/configurable/date/CDatePicker.vue'

export default defineComponent({
  props: { ...facetProps },
  components: { CDatePicker },
  setup(props) {
    const today = new Date().toISOString()
    const maxDate = addYears(new Date(), 1).toISOString()

    const { urlArg } = toRefs(props)

    const dateFrom = ref<Date | null>(null)
    const dateTo = ref<Date | null>(null)

    const i18n = useI18n()
    const { changeParam } = useQuickSearchParams()

    const datepickerLocale = computed(() => i18n.locale)

    function onDateFromInput(date: Date) {
      changeParam(`${urlArg.value}-from`, formatDate(date))
    }

    function onDateToInput(date: Date) {
      changeParam(`${urlArg.value}-to`, formatDate(date))
    }

    function formatDate(date: Date) {
      return format(date, 'd/M/yyyy')
    }

    return {
      datepickerLocale,
      maxDate,
      today,
      dateFrom,
      dateTo,
      onDateFromInput,
      onDateToInput
    }
  }
})
