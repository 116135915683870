







import { Option } from '~/models/shared/types'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    focused: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    option: {
      type: Object as PropType<Option>,
      required: true
    }
  }
})
