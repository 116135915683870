


























import { FeedModel } from '~/models/user/types'
import { formatNumber } from '~/utils/number'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    model: {
      type: Object as PropType<FeedModel>,
      required: true
    },
    showLink: {
      type: Boolean,
      required: false,
      default: true
    },
    rowStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    feedLabel: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    modelUrl() {
      return this.model.targetUrl
    },
    formattedCount() {
      return formatNumber(this.model.classifiedsCount)
    }
  },
  mounted() {
    this.$emit('image-load')
  },
  methods: {
    hrefClick(e) {
      e.preventDefault()
      e.stopPropagation()
      if (this.showLink) {
        this.modelClicked(e)
      }
      return false
    },
    modelClicked(evt) {
      this.$analytics.recordEvent({
        namespace: 'n_index_feed_model',
        action: 'click',
        label: this.feedLabel + ' (' + this.model.title + ')'
      })

      if (evt) {
        evt.preventDefault()
        if (evt.ctrlKey) {
          window.open(this.modelUrl)
          return
        }
      }

      return this.$router.push({ path: this.modelUrl })
    }
  }
})
