







import { capitalizeAllWords } from '~/utils/string'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    unit: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    capitalizeAllWords
  }
})
