

















































































import { computed, defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  setup() {
    const bannerUrl = computed(() => {
      return '/leasing-cars/'
    })

    return {
      bannerUrl
    }
  }
})
