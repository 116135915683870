












import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    queries: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  setup() {}
})
