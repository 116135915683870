





































































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs,
  useRoute,
  useRouter,
  watch,
  vue3Model
} from '~/utils/nuxt3-migration'

import { TranslateResult } from 'vue-i18n'
import CInputGroup from '~/components/shared/configurable/form/input/CInputGroup.vue'
import { ciChevronLeft } from '~/icons/source/regular/chevron-left'
import { ciChevronRight } from '~/icons/source/regular/chevron-right'
import { ciAngleRight } from '~/icons/source/regular/angle-right'
import { range } from '~/utils/array'
import { formatNumber } from '~/utils/number'

const boundaryCount = 1

export default defineComponent({
  model: vue3Model,
  components: {
    CInputGroup
  },
  props: {
    modelValue: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 10
    },
    totalResults: {
      type: Number,
      default: 1
    },
    pagesCount: {
      type: Number,
      default: null
    },
    siblingCount: {
      type: Number,
      default: 1
    },
    siblingOffset: {
      type: Number,
      default: 2
    },
    showCounts: {
      type: Boolean,
      default: true
    },
    showRangeCounts: {
      type: Boolean,
      default: false
    },
    showCustomSelect: {
      type: Boolean,
      default: false
    },
    resultsText: {
      type: String as PropType<TranslateResult>,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String as PropType<'primary' | 'secondary'>,
      default: 'primary'
    },
    size: {
      type: String as PropType<'sm' | 'md'>,
      default: 'sm'
    },
    links: {
      type: Boolean,
      default: false
    },
    linkGen: {
      type: Function as PropType<(page: number) => string>,
      default: (pg: number) => {
        const route = useRoute()
        const router = useRouter()
        return router.resolve({
          query: { ...route.value.query, ...{ pg: pg.toString() } }
        }).href
      }
    },
    rel: {
      type: String,
      default: null
    }
  },
  setup(props, { emit }) {
    const { pagesCount, totalResults, perPage } = toRefs(props)
    const page = ref<number>(props.modelValue)
    const pageNumber = ref(page.value)

    watch(
      () => props.modelValue,
      newPage => {
        page.value = newPage
      }
    )
    watch(page, () => emit('update:modelValue', page.value))

    const startPages = computed(() =>
      range(1, Math.min(boundaryCount, computedPagesCount.value))
    )
    const endPages = computed(() =>
      range(
        Math.max(
          computedPagesCount.value - boundaryCount + 1,
          boundaryCount + 1
        ),
        computedPagesCount.value
      )
    )
    const siblingsStart = computed(() =>
      Math.max(
        Math.min(
          // Natural start
          page.value - props.siblingCount,
          // Lower boundary when page is high
          computedPagesCount.value -
            boundaryCount -
            props.siblingCount * props.siblingOffset -
            1
        ),
        // Greater than startPages
        boundaryCount + 2
      )
    )
    const siblingsEnd = computed(() =>
      Math.min(
        Math.max(
          // Natural end
          page.value + props.siblingCount,
          // Upper boundary when page is low
          boundaryCount + props.siblingCount * 2 + props.siblingOffset
        ),
        // Less than endPages
        endPages.value.length > 0
          ? endPages.value[0] - 2
          : computedPagesCount.value - 1
      )
    )
    const items = computed(() => [
      ...startPages.value,
      ...(siblingsStart.value > boundaryCount + 2
        ? ['ellipsis']
        : boundaryCount + 1 < computedPagesCount.value - boundaryCount
        ? [boundaryCount + 1]
        : []),
      // Sibling pages
      ...range(siblingsStart.value, siblingsEnd.value),
      // End ellipsis
      ...(siblingsEnd.value < computedPagesCount.value - boundaryCount - 1
        ? ['ellipsis']
        : computedPagesCount.value - boundaryCount > boundaryCount
        ? [computedPagesCount.value - boundaryCount]
        : []),
      ...endPages.value
    ])
    const computedPagesCount = computed(() => {
      if (pagesCount.value) {
        return pagesCount.value
      }
      if (totalResults.value && perPage.value) {
        return Math.ceil(totalResults.value / perPage.value)
      }
      return 1
    })
    const canGoToPreviousPage = computed(() => page.value > 1)
    const canGoToNextPage = computed(
      () => page.value < computedPagesCount.value
    )
    const rangeCountFrom = computed(() => (page.value - 1) * perPage.value + 1)
    const rangeCountTo = computed(() =>
      Math.min(page.value * perPage.value, totalResults.value)
    )
    const formattedTotalResults = computed(() =>
      formatNumber(totalResults.value)
    )
    const previousPageLink = computed(() => {
      return page.value === 1 ? {} : createPageLink(page.value - 1)
    })
    const nextPageLink = computed(() => createPageLink(page.value + 1))

    function createPageLink(pageNum: number) {
      return props.links
        ? {
            to: props.linkGen(pageNum),
            rel: props.rel
          }
        : {}
    }
    function itemIsActive(item: number) {
      return page.value === item
    }
    function handleItemClick(item: number) {
      if (page.value !== item) {
        page.value = item
      }
    }
    function goToNextPage() {
      if (canGoToNextPage.value) {
        page.value += 1
      }
    }
    function goToPreviousPage() {
      if (canGoToPreviousPage.value) {
        page.value -= 1
      }
    }
    function updatePageNumber() {
      page.value = pageNumber.value
    }

    return {
      ciChevronLeft,
      ciChevronRight,
      ciAngleRight,
      rangeCountFrom,
      rangeCountTo,
      formattedTotalResults,
      canGoToNextPage,
      canGoToPreviousPage,
      items,
      pageNumber,
      previousPageLink,
      nextPageLink,
      createPageLink,
      itemIsActive,
      handleItemClick,
      goToNextPage,
      goToPreviousPage,
      updatePageNumber
    }
  }
})
