import { CIcon } from '~/icons/types'

export const ciChevronRight: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M16.8107 10.9393C17.3964 11.5251 17.3964 12.4749 16.8107 13.0607L9.31066 20.5607C8.72487 21.1464 7.77513 21.1464 7.18934 20.5607C6.60355 19.9749 6.60355 19.0251 7.18934 18.4393L13.6287 12L7.18934 5.56066C6.60355 4.97487 6.60355 4.02513 7.18934 3.43934C7.77513 2.85355 8.72487 2.85355 9.31066 3.43934L16.8107 10.9393Z',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'chevron-right',
  type: 'regular'
}
