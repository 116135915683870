
















































import { defineComponent } from '~/utils/nuxt3-migration'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineComponent({
  components: { CImg },

  i18n: defineComponentTranslations({
    audit_title: {
      en: 'Technical Audit & Audit certificate',
      el: 'Τεχνική Αξιολόγηση & Πιστοποιητικό Ελέγχου'
    },
    for_buyers_and_sellers: {
      en: '...for vehicle buyers and sellers',
      el: '...για αγοραστές και πωλητές οχημάτων'
    }
  })
})
